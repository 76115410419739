





































import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { DutyDetails } from '../../models/duty-details.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import dayjs from 'dayjs';

@Component
export default class DashboardDutyDetails extends Vue {
  @Prop()
  dutyId!: string;

  @Getter
  dutyDetails!: (dutyId: string) => DutyDetails | undefined;

  @Action
  private loadDashboardDutyDetails!: (dutyId: string) => Promise<void>
  @Action
  private registerToDuty!: (dutyId: string) => Promise<void>
  @Action
  private unregisterFromDuty!: (dutyId: string) => Promise<void>

  async created(): Promise<void> {
    await this.loadDashboardDutyDetails(this.dutyId);
  }

  async register(): Promise<void> {
    try {
      await this.registerToDuty(this.dutyId);
    } catch(e) {
      ToastHelper.showError(this.$bvToast, `Failed to register to duty`)
    }
  }

  async unregister(): Promise<void> {
    try {
      await this.unregisterFromDuty(this.dutyId);
    } catch (e) {
      ToastHelper.showError(this.$bvToast, `Failed to unregister from duty`)
    }
  }

  showRegisterModal(): void {
    this.$bvModal.show('register-question-modal');
  }

  showUnregisterModal(): void {
    this.$bvModal.show('unregister-question-modal')
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentDutyDetails(): any {
    const dutyDetails = this.dutyDetails(this.dutyId);
    if (dutyDetails === null || dutyDetails === undefined) {
      return null;
    }
    
    return {
      description: dutyDetails.description,
      date: dayjs(dutyDetails.startAt).format('dddd DD MMMM YYYY'),
      startTime: dayjs(dutyDetails.startAt).format('HH:mm'),
      endTime: dayjs(dutyDetails.endAt).format('HH:mm'),
      role: dutyDetails.assignments[0].assignment.role,
      isAssigned: dutyDetails.assignments[0].assignment.member != null,
      assignedName: dutyDetails.assignments[0].assignment.member?.displayName,
      allowRegister: dutyDetails.canAssigne,
      allowCancel: dutyDetails.assignments[0].assignment.allowCancel
    }
  }

  get isLoading(): boolean {
    return !this.currentDutyDetails;
  }
}
