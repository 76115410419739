




































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Team } from '../../models/team.model';

@Component
export default class TeamList extends Vue {
  private readonly maxTeamsCount = 3;
  showMore = false;

  @Prop()
  text!: string;
  @Prop()
  showLink!: boolean;
  @Prop()
  linkText!: boolean;
  @Prop()
  teams!: Team[];

  get showMoreButton(): boolean {
    return !this.showMore && this.teams.length > this.maxTeamsCount;
  }

  get teamsToShow(): Team[] {
    return this.teams.filter(
      (team, index) => this.showMore || index < this.maxTeamsCount
    );
  }
}
