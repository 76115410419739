
























import { Member } from '@/modules/login/models/member.model';
import { PresenceStatus } from '@/shared/enums/presence-status.enum';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { FormRatingPlugin } from 'bootstrap-vue';
import { inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { TeamPersonWithPresence } from '../../models/match-details.model';
import { IMatchApiService } from '../../services/match-api.service';

@Component
export default class MatchTileDetilasPerson extends Vue {

    loading = false;
    
    reactionPresent = PresenceStatus.Present;
    reactionUnknown = PresenceStatus.Unknown;
    reactionAbsent = PresenceStatus.Absent;

    showDropdown = false;

    @inject()
    matchApiService!: IMatchApiService;

    @Prop()
    person!: TeamPersonWithPresence
    @Prop()
    clubId!: string;
    @Prop()
    matchId!: string;

    
    @Getter
    currentMember!: Member;

    toggleShowDropdown() : void {
        this.showDropdown = !this.showDropdown;
    }

    async changePresenceStatus(status: PresenceStatus): Promise<void> {
        try {
            this.loading = true;
            this.toggleShowDropdown();

            const oldStatus = this.person.status;
            const result = await this.matchApiService.changeMatchPresenceStatus(this.clubId, this.matchId, this.person.teamId, this.person.memberId, status);

            if (result)
            {
                this.person.status = status;
                ToastHelper.showSuccess(this.$bvToast, `Presence status has been changed`);
            }
            else 
            {
                this.person.status = oldStatus;
                ToastHelper.showError(this.$bvToast, `Fout opgetreden. Probeer het nog eens.`);
            }
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, `Fout opgetreden. Probeer het nog eens.`);
        }
        finally {
            this.loading = false;
        }
    }
}
