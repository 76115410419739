var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container event-tile-details" }, [
    _c("div", { staticClass: "row" }, [
      _vm.loading
        ? _c("div", { staticClass: "row" }, [
            _c("img", {
              staticStyle: {
                display: "block",
                "margin-left": "auto",
                "margin-right": "auto",
              },
              attrs: {
                src: require("@/assets/loading.svg"),
                alt: "loading..",
                width: "25%",
              },
            }),
          ])
        : !_vm.loading && _vm.details === null
        ? _c("div", { staticClass: "row" }, [
            _c("span", [
              _vm._v(
                _vm._s(_vm.$t("dashboard.eventDetails.errorLoadingDetails"))
              ),
            ]),
          ])
        : _c("div", { staticClass: "col p-0" }, [
            _c("div", { staticClass: "main-info-container bg-light" }, [
              _c(
                "div",
                { staticClass: "main-info pb-2 pt-2" },
                [
                  _c("div", { staticClass: "title" }, [
                    _vm._v(_vm._s(_vm.details.title)),
                  ]),
                  _c(
                    "div",
                    { staticClass: "dates" },
                    [
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "text-right pr-2" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t("dashboard.eventDetails.startsAt")
                              ) + ":"
                            ),
                          ]),
                          _c("b-col", { staticClass: "text-left pl-2" }, [
                            _vm._v(_vm._s(_vm.getDate(_vm.details.startDate))),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c("b-col", { staticClass: "text-right pr-2" }, [
                            _vm._v(
                              _vm._s(_vm.$t("dashboard.eventDetails.endsAt")) +
                                ":"
                            ),
                          ]),
                          _c("b-col", { staticClass: "text-left pl-2" }, [
                            _vm._v(_vm._s(_vm.getDate(_vm.details.endDate))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.details.allowSignup
                    ? _c(
                        "b-button",
                        {
                          staticClass: "mt-2",
                          attrs: {
                            size: "sm",
                            disabled: _vm.registerLoading,
                            variant: "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.register()
                            },
                          },
                        },
                        [
                          _vm.registerLoading
                            ? _c("b-spinner", {
                                attrs: { small: "", variant: "light" },
                              })
                            : _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("dashboard.eventDetails.register")
                                  )
                                ),
                              ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "section no-border" },
                [
                  _c("div", { staticClass: "title players-title" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("dashboard.eventDetails.attendees")) + ":"
                      ),
                    ]),
                  ]),
                  _vm._l(_vm.details.attendees, function (attendee) {
                    return _c("div", { key: attendee.id }, [
                      attendee.id === _vm.currentMember.id
                        ? _c("span", [_c("b", [_vm._v(_vm._s(attendee.name))])])
                        : _c("span", [_vm._v(_vm._s(attendee.name))]),
                    ])
                  }),
                ],
                2
              ),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }