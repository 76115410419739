var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "list-group-item list-group-item-action d-flex align-items-center collapsed",
      },
      [
        _c("div", { staticClass: "d-flex matchinfo w-100" }, [
          _c("div", { staticClass: "col col-lg align-items-center pr-2" }, [
            _c("span", { staticClass: "club d-block" }, [
              _c("i", { staticClass: "fas fa-whistle mr-2 text-primary" }),
              _c("strong", [_vm._v("Wedstrijd " + _vm._s(_vm.teamsNames))]),
            ]),
            _c("span", { staticClass: "club d-block text-muted" }, [
              _vm._v(_vm._s(_vm.tile.clubMemberFullName)),
            ]),
            _c(
              "span",
              {
                staticClass:
                  "club d-block text-muted font-weight-bold small mt-1",
              },
              [
                _c("i", { staticClass: "ml-2 fas fa-map-pin fa-sm" }),
                _vm._v(
                  " " +
                    _vm._s(_vm.tile.referee.pitch.number) +
                    " " +
                    _vm._s(_vm.tile.referee.pitch.name) +
                    " "
                ),
              ]
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-4 pl-0 text-right align-items-center" },
            [
              _c("strong", [
                _vm._v(_vm._s(_vm.startTime) + " "),
                _vm.endTime ? _c("span", [_vm._v("-")]) : _vm._e(),
                _vm._v(" " + _vm._s(_vm.endTime)),
              ]),
            ]
          ),
          _vm._m(0),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "timescore align-items-center" }, [
      _c("i", { staticClass: "fal fa-chevron-up toggle-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }