var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container matchtableinfo overflow-hidden pb-3 pt-2" },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col pt-3 pt-sm-0" }, [
          _vm.isLoading
            ? _c("div", { staticClass: "row" }, [
                _c("img", {
                  staticStyle: {
                    display: "block",
                    "margin-left": "auto",
                    "margin-right": "auto",
                  },
                  attrs: {
                    src: require("@/assets/loading.svg"),
                    alt: "loading..",
                    width: "25%",
                  },
                }),
              ])
            : _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.currentDutyDetails.description)),
                  ]),
                ]),
                _vm.currentDutyDetails.isAssigned
                  ? _c("div", { staticClass: "col-12" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.currentDutyDetails.assignedName)),
                      ]),
                    ])
                  : _vm.currentDutyDetails.allowRegister
                  ? _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "SpinnerButton",
                          {
                            ref: "registerButton",
                            on: { click: _vm.registerToDuty },
                          },
                          [_vm._v("Inschrijven")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("div", { staticClass: "col-12" }, [
                  _vm._v(_vm._s(_vm.currentDutyDetails.date)),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _vm._v(
                    _vm._s(_vm.currentDutyDetails.startTime) +
                      " - " +
                      _vm._s(_vm.currentDutyDetails.endTime)
                  ),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _vm._v("Taak: " + _vm._s(_vm.currentDutyDetails.role)),
                ]),
                _vm.currentDutyDetails.allowCancel
                  ? _c(
                      "div",
                      { staticClass: "col-12" },
                      [
                        _c(
                          "SpinnerButton",
                          {
                            ref: "unregisterButton",
                            on: { click: _vm.unregisterFromDuty },
                          },
                          [_vm._v("Uitschrijven")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
        ]),
      ]),
      _c(
        "b-modal",
        {
          attrs: { id: "register-question-modal", title: "Inschrijven" },
          on: {
            ok: function ($event) {
              return _vm.register()
            },
            cancel: function ($event) {
              return _vm.$refs.registerButton.release()
            },
          },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v("Weet u zeker dat u zich wilt inschrijven voor de dienst?"),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: { id: "unregister-question-modal", title: "Uitschrijven" },
          on: {
            ok: function ($event) {
              return _vm.unregister()
            },
            cancel: function ($event) {
              return _vm.$refs.unregisterButton.release()
            },
          },
        },
        [
          _c("p", { staticClass: "my-4" }, [
            _vm._v("Weet u zeker dat u zich wilt uitschrijven voor de dienst?"),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }