var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container match-details" }, [
    _c("div", { staticClass: "row" }, [
      _vm.loading
        ? _c("div", { staticClass: "row", staticStyle: { padding: "15px" } }, [
            _c("img", {
              staticStyle: {
                display: "block",
                "margin-left": "auto",
                "margin-right": "auto",
              },
              attrs: {
                src: require("@/assets/loading.svg"),
                alt: "loading..",
                width: "25%",
              },
            }),
          ])
        : !_vm.loading && _vm.details === null
        ? _c("div", [
            _c("span", [
              _vm._v(_vm._s(_vm.$t("dashboard.matchDetails.error"))),
            ]),
          ])
        : _c("div", { staticClass: "col p-0" }, [
            _c("div", { staticClass: "main-info-container bg-light" }, [
              _c(
                "div",
                { staticClass: "main-info" },
                [
                  _c("div", { staticClass: "main-title" }, [
                    _vm._v(_vm._s(_vm.$t("dashboard.matchDetails.title"))),
                  ]),
                  _c("div", { staticClass: "main-date" }, [
                    _vm._v(_vm._s(_vm.matchDate(_vm.details.date))),
                  ]),
                  _vm.details.startsAt !== null
                    ? _c("div", { staticClass: "main-start-time" }, [
                        _vm._v(_vm._s(_vm.parseTime(_vm.details.startsAt))),
                      ])
                    : _vm._e(),
                  _vm.details.meetingTime !== null
                    ? _c("div", { staticClass: "main-meeting-time" }, [
                        _vm._v(_vm._s(_vm.parseTime(_vm.details.meetingTime))),
                      ])
                    : _vm._e(),
                  _vm.showScore(_vm.details.result)
                    ? _c("div", { staticClass: "result" }, [
                        _c(
                          "span",
                          {
                            staticClass: "badge result-badge d-block",
                            class: _vm.resultBadgeClass(
                              _vm.details.result.resultStatus
                            ),
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getScore(_vm.details.result)) +
                                " "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _c(
                    "b-container",
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticClass: "team" },
                            [
                              _c(
                                "b-img",
                                _vm._b(
                                  {
                                    attrs: {
                                      rounded: "circle",
                                      src: _vm.details.homeTeam.clubLogoUrl,
                                    },
                                  },
                                  "b-img",
                                  {
                                    blank:
                                      _vm.details.homeTeam.clubLogoUrl === null,
                                    blankColor: "#777",
                                    width: 100,
                                    height: 100,
                                    class: "m1",
                                  },
                                  false
                                )
                              ),
                              _c("div", { staticClass: "team-name" }, [
                                _vm._v(_vm._s(_vm.details.homeTeam.teamName)),
                              ]),
                              _c("div", { staticClass: "club-name" }, [
                                _vm._v(_vm._s(_vm.details.homeTeam.clubName)),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticClass: "team" },
                            [
                              _c(
                                "b-img",
                                _vm._b(
                                  {
                                    attrs: {
                                      rounded: "circle",
                                      src: _vm.details.awayTeam.clubLogoUrl,
                                    },
                                  },
                                  "b-img",
                                  {
                                    blank:
                                      _vm.details.awayTeam.clubLogoUrl === null,
                                    blankColor: "#777",
                                    width: 100,
                                    height: 100,
                                    class: "m1",
                                  },
                                  false
                                )
                              ),
                              _c("div", { staticClass: "team-name" }, [
                                _vm._v(_vm._s(_vm.details.awayTeam.teamName)),
                              ]),
                              _c("div", { staticClass: "club-name" }, [
                                _vm._v(_vm._s(_vm.details.awayTeam.clubName)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "section" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("dashboard.matchDetails.location"))),
                  ]),
                ]),
                _c("div", { staticClass: "address-info" }, [
                  _c("span", [_vm._v(_vm._s(_vm.details.location))]),
                  _c("br"),
                  _c(
                    "span",
                    [
                      _vm._v(_vm._s(_vm.details.field) + " "),
                      _vm.details.fieldPart !== null &&
                      _vm.details.fieldPart !== ""
                        ? [_vm._v("(" + _vm._s(_vm.details.fieldPart) + ")")]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
            _vm.details.referees.length > 0
              ? _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "section" }, [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("dashboard.matchDetails.referees"))
                        ),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "address-info" },
                      _vm._l(_vm.details.referees, function (referee, index) {
                        return _c("span", { key: index }, [
                          _vm._v(_vm._s(referee)),
                        ])
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "section" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("dashboard.matchDetails.comments"))),
                  ]),
                ]),
                _c("div", { staticClass: "address-info" }, [
                  _vm.details.remarks !== null && _vm.details.remarks !== ""
                    ? _c("span", [_vm._v(_vm._s(_vm.details.remarks))])
                    : _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("dashboard.matchDetails.noComments"))
                        ),
                      ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "section" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("dashboard.matchDetails.alternateKit"))
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "address-info" }, [
                  _vm.details.alternateKit
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "dashboard.matchDetails.alternateKitRequired"
                            )
                          )
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "dashboard.matchDetails.alternateKitNotRequired"
                            )
                          )
                        ),
                      ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "section" }, [
                _c("div", { staticClass: "title" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("dashboard.matchDetails.dwf"))),
                  ]),
                ]),
                _c("div", { staticClass: "address-info" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("dashboard.matchDetails.dwfHome")) +
                        ": " +
                        _vm._s(_vm.details.dwhCodeHome)
                    ),
                  ]),
                  _c("br"),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.$t("dashboard.matchDetails.dwfAway")) +
                        ": " +
                        _vm._s(_vm.details.dwfCodeAway)
                    ),
                  ]),
                  _c("br"),
                  _c("span", [
                    _c("i", { staticClass: "fas fa-xs fa-whistle" }),
                    _vm._v(": " + _vm._s(_vm.details.dwfCodeReferee)),
                  ]),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                { staticClass: "section no-border" },
                [
                  _c("div", { staticClass: "title players-title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("dashboard.matchDetails.players"))),
                    ]),
                  ]),
                  _vm._l(_vm.details.persons, function (person) {
                    return _c(
                      "div",
                      { key: person.memberId, staticClass: "player-info" },
                      [
                        _c("match-person", {
                          attrs: {
                            person: person,
                            clubId: _vm.clubId,
                            matchId: _vm.matchId,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }