


























import { Vue, Component, Prop } from 'vue-property-decorator';
import { Tile } from '../../models/tile.model';
import AgendaTileDetails from './AgendaTileDetails.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    'agenda-tile-details': AgendaTileDetails
  }
})
export default class EventTile extends Vue {

    showDetails = false;

    @Prop()
    tile!: Tile;

    get startTime() : string {
        return dayjs(this.tile.agenda.startsAt).format('HH:mm');
    }

    get endTime() : string {
        return dayjs(this.tile.agenda.endsAt).format('HH:mm');
    }

    toggleShowDetails() : void {
        this.showDetails = !this.showDetails;
    }
}
