import { PresenceStatus } from "@/shared/enums/presence-status.enum";

export interface MatchDetails {
    id: string;
    matchNumer: string;
    isHome: boolean;
    location: string;
    field: string;
    fieldPart: string;
    date: Date;
    startAt: string;
    meetingTime: string | null;
    homeTeam: ClubTeamBase;
    awayTeam: ClubTeamBase;
    dwfCodeReferee: string;
    dwhCodeHome: string;
    dwfCodeAway: string;
    remarks: string;
    alternateKit: string;
    referees: string[];
    persons: TeamPersonWithPresence[];
    result: MatchResults;
}

export interface ClubTeamBase {
    clubName: string;
    clubLogoUrl: string;
    teamName: string;
}

export interface TeamPersonWithPresence {
    memberId: string;
    teamId: string;
    memberName: string;
    status:PresenceStatus;
    allowChange: boolean;
}

export interface MatchResults {
    resultStatus: MatchResultStatus | null;
    homeScore: number | null;
    awayScore: number | null;
    homePenalty: number | null;
    awayPenalty: number | null;
}

export enum MatchResultStatus {
    HomeTeamWon = 'homeTeamWon',
    AwayTeamWon = 'awayTeamWon',
    Draw = 'draw'
}