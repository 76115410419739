var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "player-row" }, [
    _c("div", [_c("span", [_vm._v(_vm._s(_vm.person.memberName))])]),
    _c("div", [
      _c("div", { staticClass: "dropdown" }, [
        _c(
          "button",
          {
            staticClass: "btn bg-white border p-0 mt-1",
            attrs: {
              disabled: _vm.loading || !_vm.person.allowChange,
              type: "button",
              id: "dropdownMenuButton",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
            },
            on: {
              click: [
                _vm.toggleShowDropdown,
                function ($event) {
                  $event.stopPropagation()
                },
              ],
            },
          },
          [
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticClass: "fas fa-check fa-sm mx-2",
              class: {
                "text-muted": _vm.person.status != this.reactionPresent,
                "text-success": _vm.person.status == this.reactionPresent,
              },
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticClass: "fas fa-question fa-sm mx-2",
              class: {
                "text-muted": _vm.person.status != this.reactionUnknown,
                "text-warning": _vm.person.status == this.reactionUnknown,
              },
            }),
            _c("i", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.loading,
                  expression: "!loading",
                },
              ],
              staticClass: "fas fa-times fa-sm mx-2",
              class: {
                "text-muted": _vm.person.status != this.reactionAbsent,
                "text-danger": _vm.person.status == this.reactionAbsent,
              },
            }),
            _c("b-spinner", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { small: "", variant: "primary" },
            }),
          ],
          1
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showDropdown,
                expression: "showDropdown",
              },
            ],
            staticClass: "dropdown-menu show",
            attrs: { "aria-labelledby": "dropdownMenuButton" },
          },
          [
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: [
                    function () {
                      return _vm.changePresenceStatus(this$1.reactionPresent)
                    },
                    function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                    },
                  ],
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-fw fa-check fa-sm mr-2 text-success",
                }),
                _vm._v(_vm._s(_vm.$t("shared.presence.present"))),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: [
                    function () {
                      return _vm.changePresenceStatus(this$1.reactionUnknown)
                    },
                    function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                    },
                  ],
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-fw fa-question fa-sm mr-2 text-warning",
                }),
                _vm._v(_vm._s(_vm.$t("shared.presence.unknown"))),
              ]
            ),
            _c(
              "a",
              {
                staticClass: "dropdown-item",
                attrs: { href: "#" },
                on: {
                  click: [
                    function () {
                      return _vm.changePresenceStatus(this$1.reactionAbsent)
                    },
                    function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                    },
                  ],
                },
              },
              [
                _c("i", {
                  staticClass: "fas fa-fw fa-times fa-sm mr-2 text-danger",
                }),
                _vm._v(_vm._s(_vm.$t("shared.presence.absent"))),
              ]
            ),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }