































































import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Match } from '../../models/match.model';
import dayjs from 'dayjs';
@Component
export default class DashboardMatchDetails extends Vue {
  @Prop()
  matchId!: string

  @Action
  private loadDashboardMatchDetails!: (matchId: string) => Promise<void>

  @Getter
  matchDetails!: Match | null;

  async created(): Promise<void> {
    await this.loadDashboardMatchDetails(this.matchId);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentMatchDetails(): any {
    if (!this.matchDetails) {
      return null;
    }
    const res = this.matchDetails!.result;
    const hasResult = res != null && Object.entries(res).length !== 0;
    let result = null;
    if(res)
    {
        result = `${res.homescore ?? 0}${res.homeextratime ? '/' + res.homeextratime : ''} - ${res.awayscore?? 0}${res.awayextratime ? '/' + res.awayextratime : ''}`;
        if(res.homepenalty)
          result += ` (${res.homepenalty} - ${res.awaypenalty})`;
    }

    return {
      fieldName : `${this.matchDetails.pitch?.number??''} ${this.matchDetails.pitch?.name??''}`,
      startTime: dayjs(this.matchDetails.startsAt).format('HH:mm'),
      meetingTime : this.matchDetails.meetingTime != null ? dayjs(this.matchDetails.meetingTime).format('HH:mm') : null,
      referees: this.matchDetails.matchUmpires ?? [],
      matchNumber : this.matchDetails.matchNumber,
      clubRemarks : this.matchDetails.clubRemarks ?? '-',
      federationRemarks : this.matchDetails.federationRemarks ?? '-',
      hasResult : hasResult,
      result : result,
    }
  }

  get isLoading(): boolean {
    return !this.currentMatchDetails;
  }
}
