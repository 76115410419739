var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container matchtableinfo overflow-hidden pb-3 pt-2" },
    [
      _c("div", { staticClass: "row" }, [
        _vm.isLoading
          ? _c("div", {}, [
              _vm.isLoading
                ? _c("div", { staticClass: "row" }, [
                    _c("img", {
                      staticStyle: {
                        display: "block",
                        "margin-left": "auto",
                        "margin-right": "auto",
                      },
                      attrs: {
                        src: require("@/assets/loading.svg"),
                        alt: "loading..",
                        width: "25%",
                      },
                    }),
                  ])
                : _vm._e(),
            ])
          : _c("div", { staticClass: "col pt-3 pt-sm-0" }, [
              _c("div", { staticClass: "row" }, [
                !_vm.currentMatchDetails.hasResult
                  ? _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "row py-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _vm._v("Verzamelen"),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.currentMatchDetails.meetingTime) + " "
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _c("div", { staticClass: "col-6" }, [
                  _c("div", { staticClass: "row py-2" }, [
                    _c("div", { staticClass: "col-12" }, [_vm._v("Spelen")]),
                    _c("div", { staticClass: "col-12" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.currentMatchDetails.startTime)),
                      ]),
                    ]),
                  ]),
                ]),
                _vm.currentMatchDetails.hasResult
                  ? _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "row py-2" }, [
                        _c("div", { staticClass: "col-12" }, [
                          _vm._v("Resultaat"),
                        ]),
                        _c("div", { staticClass: "col-12" }, [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.currentMatchDetails.result)),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row py-2" }, [
                    _c("div", { staticClass: "col-12" }, [_vm._v("Veld")]),
                    _c("div", { staticClass: "col-12" }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.currentMatchDetails.fieldName)),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row py-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v("Wedstrijdnummer"),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.currentMatchDetails.matchNumber)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row py-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v("Scheidsrechter(s)"),
                ]),
                _c(
                  "div",
                  { staticClass: "col-12" },
                  [
                    _c(
                      "strong",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: !_vm.currentMatchDetails.referees,
                            expression: "!currentMatchDetails.referees",
                          },
                        ],
                      },
                      [_vm._v("-")]
                    ),
                    _vm._l(
                      _vm.currentMatchDetails.referees,
                      function (referee) {
                        return _c(
                          "strong",
                          { key: referee.clubMemberId, staticClass: "mr-2" },
                          [_vm._v(" " + _vm._s(referee.displayName) + " ")]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "row py-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v("Club opmerking"),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.currentMatchDetails.clubRemarks)),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "row py-2" }, [
                _c("div", { staticClass: "col-12" }, [
                  _vm._v("Extra opmerking"),
                ]),
                _c("div", { staticClass: "col-12" }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.currentMatchDetails.federationRemarks)),
                  ]),
                ]),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }