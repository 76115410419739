























import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MemberWithPresence } from '../../models/training-details.model';
import { PresenceStatus } from '../../../../shared/enums/presence-status.enum';
import { Member } from '../../../login/models/member.model';
import { ToastHelper } from '../../../../shared/helpers/toast.helper';

@Component
export default class TrainingTailDetailsPlayer extends Vue {
    
    reactionPresent = PresenceStatus.Present;
    reactionUnknown = PresenceStatus.Unknown;
    reactionAbsent = PresenceStatus.Absent;

    showDropdown = false;
    
    @Prop()
    person!: MemberWithPresence;
    @Prop()
    clubId!: '';
    @Prop()
    trainingId!: '';
    @Prop()
    teamId!: '';
    @Prop()
    isAssistant!: boolean;
        
    @Action
    private changeTrainingPresenceStatusOtherMember!: (payload: {clubId: string, trainingId: string, teamId: string, memberId: string, status: PresenceStatus}) => Promise<void>

    @Getter
    currentMember!: Member;
    
    toggleShowDropdown(): void {
        this.showDropdown = !this.showDropdown;
    }

    canChangeStatus(id: string) : boolean {
        return this.isAssistant || this.currentMember.id === id;
    }

    async changePresenceStatus(status: PresenceStatus): Promise<void> {

    if (this.person.status == status) {
      return;
    }

    try {
      const payload = {
        clubId: this.clubId,
        trainingId: this.trainingId,
        teamId: this.teamId,
        memberId: this.person.clubMemberId,
        status: status
      }

      await this.changeTrainingPresenceStatusOtherMember(payload);
      
      this.person.status = status;
      ToastHelper.showSuccess(this.$bvToast, `Presence status has been changed`);
    } catch {
      ToastHelper.showError(this.$bvToast, `Fout opgetreden. Probeer het nog eens.`);
    } finally {
      this.toggleShowDropdown();
    }
  }
}
