





















































import { Action } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { PresenceStatus } from '../../../../shared/enums/presence-status.enum';
import { Tile } from '../../models/tile.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import TrainingTileDetails from './TrainingTileDetails.vue';
import dayjs from 'dayjs';

@Component({
  components: {
    "training-details": TrainingTileDetails
  } 
})
export default class TrainingTile extends Vue {
  @Prop()
  tile!: Tile;

  showDetails = false;
  showDropdown = false;
  currentPresenceStatus: PresenceStatus | null = null;
  presentReactions = 0;
  totalReactions = 0;
  fieldName = '';

  reactionPresent = PresenceStatus.Present;
  reactionUnknown = PresenceStatus.Unknown;
  reactionAbsent = PresenceStatus.Absent;

  @Action
  private changeTrainingPresenceStatus!: (payload: {clubId: string, trainingId: string, teamId: string, status: PresenceStatus}) => Promise<void>

  mounted(): void {
    this.currentPresenceStatus = this.tile.training.presence.status;
    const reactions =  this.tile.training.presence.reactions;
    this.presentReactions = reactions.present;
    this.totalReactions = reactions.unknown + reactions.present + reactions.absent;
    this.fieldName = this.tile.training.pitch?.name ?? '-';
  }

  toggleShowDetails(): void {
    this.showDetails = !this.showDetails;
  }

  toggleShowDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  async changePresenceStatus(status: PresenceStatus): Promise<void> {
    if (this.currentPresenceStatus == status) {
      return;
    }
    try {
      const payload = {
        clubId: this.tile.clubId,
        trainingId: this.tile.training.id,
        teamId: this.tile.teamId,
        status: status
      }
      await this.changeTrainingPresenceStatus(payload);
      if (this.currentPresenceStatus == this.reactionPresent) {
        this.presentReactions--;
      } else if (status == this.reactionPresent) {
        this.presentReactions++;
      }
      this.currentPresenceStatus = status;
      ToastHelper.showSuccess(this.$bvToast, `Presence status has been changed`);
    } catch {
      ToastHelper.showError(this.$bvToast, `Fout opgetreden. Probeer het nog eens.`);
    } finally {
      this.toggleShowDropdown();
    }
  }

  get teamName(): string {
    return this.tile.training.teamAbbreviation && this.tile.training.teamAbbreviation !== '' ? 
      this.tile.training.teamAbbreviation :
      this.tile.training.teamName
  }

  get startTime(): string {
    return dayjs(this.tile.training.startsAt).format('HH:mm');
  }
  
  get endTime(): string {
    return dayjs(this.tile.training.endsAt).format('HH:mm');
  }
  get hasTrainers(): boolean {
    return Boolean(this.tile.training.trainers?.length ?? 0 > 0);
  }
  get trainersNames(): string {
    let names = '';
    if(this.tile.training.trainers.length > 1)
    {
        names = `${this.tile.training.trainers[0].displayName}, ${this.tile.training.trainers[1].displayName.substring(0, 2)}...`
    }
    else if (this.tile.training.trainers.length > 0)
    {
      names = `${this.tile.training.trainers[0].displayName}`
    }

    return names;
  }
}

