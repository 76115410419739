var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h6", { staticClass: "mb-2 text-dark" }, [_vm._v(_vm._s(_vm.text))]),
    _c(
      "div",
      { staticClass: "list-group mb-4" },
      [
        _vm._l(_vm.teamsToShow, function (team) {
          return _c(
            "router-link",
            {
              key: team.id,
              staticClass: "list-group-item list-group-item-action py-2 px-3",
              attrs: { to: "/teams/" + team.id },
            },
            [
              _c("span", { staticClass: "d-block text-dark" }, [
                _vm._v(_vm._s(team.name)),
              ]),
            ]
          )
        }),
        _vm.showMoreButton
          ? _c(
              "a",
              {
                staticClass: "list-group-item list-group-item-action py-2 px-3",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.showMore = !_vm.showMore
                  },
                },
              },
              [_vm._m(0)]
            )
          : _vm._e(),
        _vm.showLink
          ? _c(
              "a",
              {
                staticClass: "list-group-item list-group-item-action py-2 px-3",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$emit("linkClicked")
                  },
                },
              },
              [
                _c("span", { staticClass: "d-block text-primary" }, [
                  _c("i", { staticClass: "fa fa-plus fa-xs mr-2" }),
                  _vm._v(_vm._s(_vm.linkText)),
                ]),
              ]
            )
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "d-block text-primary" }, [
      _c("i", { staticClass: "fa fa-plus fa-xs mr-2" }),
      _vm._v("Toon meer"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }