






























































import { Action, Getter } from 'vuex-class';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { MemberWithPresence, TrainingDetails } from '../../models/training-details.model';
import { PresenceStatus } from '../../../../shared/enums/presence-status.enum';
import TrainingTailDetailsPlayer from './TrainingTailDetailsPlayer.vue';
import { Member } from '../../../login/models/member.model';
import { Club } from '../../../login/models/club.model';
import { DateHelper } from '@/shared/helpers/date.helper';

@Component({
    components: {
        'training-player': TrainingTailDetailsPlayer
    }
})
export default class TrainingTileDetails extends Vue {
    @Prop()
    trainingId!: '';
    @Prop()
    teamId!: '';
    @Prop()
    eventId!: '';

    @Action
    private getTrainingsDetails!: (payload: { trainingId: string, occurenceId: string, teamId: string }) => Promise<void>

    @Getter
    trainingDetails!: (trainingId: string, occurenceId: string) => TrainingDetails | undefined;
    @Getter
    currentMember!: Member;
    @Getter
    private currentClub!: Club;

    async created() : Promise<void> {
        await this.getTrainingsDetails({ 
            trainingId: this.trainingId, 
            occurenceId: this.eventId,
            teamId: this.teamId
        });
    }

    parseTime(date: Date) : string {
        return DateHelper.parseTime(date);
    }

    trainingDate(date: Date) : string {
        return DateHelper.parseDate(date, 'dddd D MMM')
    }

    get currentDetails() : any {
        const details = this.trainingDetails(this.trainingId, this.eventId);

        if (details === undefined) {
            return null;
        }

        return details;
    }

    get loading() : boolean {
        return !this.currentDetails;
    }

    get activePlayers() : string {
        if (!this.currentDetails) {
            return '';
        }

        const totalPlayers = this.currentDetails.members.length;
        const presentPlayers = (this.currentDetails.members as MemberWithPresence[]).filter(x => x.status === PresenceStatus.Present).length;

        return `${presentPlayers}/${totalPlayers}`;
    }
}
