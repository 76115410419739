var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container training-details overflow-hidden" },
    [
      _c("div", { staticClass: "row" }, [
        _vm.loading
          ? _c("div", { staticClass: "row p-2" }, [
              _c("img", {
                staticStyle: {
                  display: "block",
                  "margin-left": "auto",
                  "margin-right": "auto",
                },
                attrs: {
                  src: require("@/assets/loading.svg"),
                  alt: "loading..",
                  width: "25%",
                },
              }),
            ])
          : _c("div", { staticClass: "col p-0" }, [
              _c("div", { staticClass: "main-info-container bg-light" }, [
                _c("div", { staticClass: "main-info" }, [
                  _c("div", { staticClass: "main-title" }, [
                    _vm._v("Training"),
                  ]),
                  _c("div", { staticClass: "main-date" }, [
                    _vm._v(
                      _vm._s(_vm.trainingDate(_vm.currentDetails.startDate))
                    ),
                  ]),
                  _c("div", { staticClass: "main-team" }, [
                    _vm._v(_vm._s(_vm.currentDetails.team.name)),
                  ]),
                  _c("div", { staticClass: "main-date" }, [
                    _vm._v(
                      _vm._s(_vm.parseTime(_vm.currentDetails.startDate)) +
                        " - " +
                        _vm._s(_vm.parseTime(_vm.currentDetails.endDate))
                    ),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "section" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.$t("dashboard.trainingDetails.address"))
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "address-info" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.currentDetails.address.street) +
                          " " +
                          _vm._s(_vm.currentDetails.address.houseNumber) +
                          _vm._s(
                            _vm.currentDetails.address.houseNumberExtension
                          )
                      ),
                    ]),
                    _c("br"),
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.currentDetails.address.zipCode) +
                          " " +
                          _vm._s(_vm.currentDetails.address.city)
                      ),
                    ]),
                    _c("br"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "section" },
                  [
                    _c("div", { staticClass: "title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("dashboard.trainingDetails.trainers"))
                        ),
                      ]),
                    ]),
                    _vm._l(_vm.currentDetails.trainers, function (person) {
                      return _c(
                        "div",
                        { key: person.id, staticClass: "trainer-info" },
                        [
                          _c("span", [
                            _vm._v(_vm._s(person.clubMemberFullName)),
                          ]),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c("div", { staticClass: "section" }, [
                  _c("div", { staticClass: "title" }, [
                    _c("span", [
                      _vm._v(_vm._s(_vm.$t("dashboard.trainingDetails.field"))),
                    ]),
                  ]),
                  _c("div", { staticClass: "field-info" }, [
                    _c("span", [_vm._v(_vm._s(_vm.currentDetails.field))]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "section no-border" },
                  [
                    _c("div", { staticClass: "title players-title" }, [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("dashboard.trainingDetails.players"))
                        ),
                      ]),
                      _c("span", [_vm._v(_vm._s(_vm.activePlayers))]),
                    ]),
                    _vm._l(_vm.currentDetails.members, function (person) {
                      return _c(
                        "div",
                        { key: person.id, staticClass: "player-info" },
                        [
                          _c("training-player", {
                            attrs: {
                              person: person,
                              teamId: _vm.teamId,
                              trainingId: _vm.eventId,
                              clubId: _vm.currentClub.id,
                              isAssistant: _vm.currentDetails.team.isAssistant,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }