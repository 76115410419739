var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row" }, [
    _c("h5", { staticClass: "col-12 mt-3 mb-3" }, [_vm._v("Dashboard")]),
    _c(
      "div",
      { staticClass: "col-12 col-sm-8 container" },
      [
        _vm.showAllMenu
          ? [
              _vm.dashboardPinnedItems.length > 0
                ? _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      { staticClass: "col mb-3" },
                      _vm._l(_vm.dashboardPinnedItems, function (pinnedItem) {
                        return _c(
                          "div",
                          {
                            key: pinnedItem.id,
                            staticClass:
                              "alert alert-primary alert-dismissible fade show d-flex align-items-center mb-1",
                            attrs: { role: "alert" },
                          },
                          [
                            _c("i", {
                              staticClass: "fas fa-field-hockey mr-3",
                            }),
                            _c(
                              "a",
                              {
                                staticClass: "alert-link",
                                attrs: { href: "#" },
                              },
                              [_vm._v(_vm._s(pinnedItem.text))]
                            ),
                            _vm._m(0, true),
                          ]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col mb-3" }, [
                  _vm.loaded && _vm.tilesByDates == null
                    ? _c(
                        "div",
                        {
                          staticClass: "alert alert-warning",
                          attrs: { role: "alert" },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("dashboard.noTiles")) + " "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col mb-3" },
                  _vm._l(_vm.tilesByDates, function (day, key, index) {
                    return _c("div", { key: index, staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col mb-3" },
                        [
                          _c("h6", { staticClass: "mb-2 text-muted" }, [
                            _vm._v(_vm._s(key)),
                          ]),
                          _vm._l(day, function (tile, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "list-group mb-2" },
                              [
                                _c(_vm.getTileComponent(tile), {
                                  tag: "component",
                                  attrs: { tile: tile },
                                }),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ])
                  }),
                  0
                ),
              ]),
            ]
          : _vm._e(),
      ],
      2
    ),
    _c("div", { staticClass: "col-12 col-sm-4 container" }, [
      _vm.checkIfClubHasModule("Teams")
        ? _c("div", [
            _vm.currentMemberPersonalTeams.length > 0
              ? _c(
                  "div",
                  [
                    _c("TeamList", {
                      attrs: {
                        text: "Persoonlijke teams",
                        showLink: false,
                        teams: _vm.currentMemberPersonalTeams,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              [
                _c("TeamList", {
                  attrs: {
                    text: "Favoriete teams",
                    showLink: true,
                    linkText: "Voeg team toe",
                    teams: _vm.currentMemberFavouriteTeams,
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close h-100",
        attrs: {
          type: "button",
          "data-dismiss": "alert",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("�")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }