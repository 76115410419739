var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "list-group-item list-group-item-action d-flex align-items-center",
          attrs: { role: "button" },
          on: { click: _vm.toggleShowDetails },
        },
        [
          _c("div", { staticClass: "d-flex matchinfo w-100" }, [
            _c("div", { staticClass: "col col-lg align-items-center pr-2" }, [
              _c("span", { staticClass: "club d-block" }, [
                _c("i", {
                  staticClass: "fas fa-calendar-week mr-2 text-primary",
                }),
                _c("strong", [_vm._v(_vm._s(_vm.tile.agenda.title))]),
              ]),
              _c("span", { staticClass: "club d-block text-muted" }, [
                _vm._v(_vm._s(_vm.tile.agenda.location)),
                _vm.tile.agenda.subLocation
                  ? _c("span", [
                      _vm._v(", " + _vm._s(_vm.tile.agenda.subLocation)),
                    ])
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-4 pl-0 text-right align-items-center" },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm.startTime) + " - " + _vm._s(_vm.endTime)),
                ]),
                _c("div", [
                  _vm.tile.relationType != "none"
                    ? _c("span", { staticClass: "text-muted pull-right" }, [
                        _vm._v(_vm._s(_vm.tile.clubMemberFirstName)),
                      ])
                    : _vm._e(),
                ]),
              ]
            ),
            _vm._m(0),
          ]),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "bg-white",
          model: {
            value: _vm.showDetails,
            callback: function ($$v) {
              _vm.showDetails = $$v
            },
            expression: "showDetails",
          },
        },
        [_c("agenda-tile-details", { attrs: { eventId: _vm.tile.agenda.id } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "timescore align-items-center" }, [
      _c("i", { staticClass: "fal fa-chevron-down toggle-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }