var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "list-group-item list-group-item-action d-flex align-items-center collapsed ",
          attrs: { role: "button" },
          on: { click: _vm.toggleShowDetails },
        },
        [
          _c("div", { staticClass: "d-flex matchinfo w-100" }, [
            _c("div", { staticClass: "col col-lg align-items-center pr-2" }, [
              _c("span", { staticClass: "club d-block" }, [
                _c("i", { staticClass: "fas fa-running mr-2 text-primary" }),
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.$t("dashboard.tiles.training")) +
                      ": " +
                      _vm._s(_vm.teamName)
                  ),
                ]),
              ]),
              _c("span", { staticClass: "club d-block text-muted" }, [
                _vm._v(_vm._s(_vm.tile.clubMemberFullName)),
              ]),
              _c(
                "span",
                {
                  staticClass:
                    "club d-block text-muted font-weight-bold small mt-1",
                },
                [
                  _c("i", { staticClass: "fas fa-users fa-sm" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.presentReactions) +
                      "/" +
                      _vm._s(_vm.totalReactions) +
                      " "
                  ),
                  _c("i", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.hasTrainers,
                        expression: "hasTrainers",
                      },
                    ],
                    staticClass: "ml-2 fas fa-traffic-cone fa-sm",
                  }),
                  _vm._v(" " + _vm._s(_vm.trainersNames) + " "),
                  _c("i", { staticClass: "ml-2 fas fa-map-pin fa-sm" }),
                  _vm._v(
                    " " +
                      _vm._s(_vm.tile.training.pitch.number) +
                      " " +
                      _vm._s(_vm.tile.training.pitch.name) +
                      " "
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-4 pl-0 text-right align-items-center" },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm.startTime) + " - " + _vm._s(_vm.endTime)),
                ]),
                _c("div", { staticClass: "dropdown" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn bg-white border p-2 mt-1",
                      attrs: {
                        type: "button",
                        id: "dropdownMenuButton",
                        "data-toggle": "dropdown",
                        "aria-haspopup": "true",
                        "aria-expanded": "false",
                      },
                      on: {
                        click: [
                          _vm.toggleShowDropdown,
                          function ($event) {
                            $event.stopPropagation()
                          },
                        ],
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-check fa-sm mx-2",
                        class: {
                          "text-muted":
                            this.currentPresenceStatus != this.reactionPresent,
                          "text-success":
                            this.currentPresenceStatus == this.reactionPresent,
                        },
                      }),
                      _c("i", {
                        staticClass: "fas fa-question fa-sm mx-2",
                        class: {
                          "text-muted":
                            this.currentPresenceStatus != this.reactionUnknown,
                          "text-warning":
                            this.currentPresenceStatus == this.reactionUnknown,
                        },
                      }),
                      _c("i", {
                        staticClass: "fas fa-times fa-sm mx-2",
                        class: {
                          "text-muted":
                            this.currentPresenceStatus != this.reactionAbsent,
                          "text-danger":
                            this.currentPresenceStatus == this.reactionAbsent,
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showDropdown,
                          expression: "showDropdown",
                        },
                      ],
                      staticClass: "dropdown-menu show",
                      attrs: { "aria-labelledby": "dropdownMenuButton" },
                    },
                    [
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: [
                              function () {
                                return _vm.changePresenceStatus(
                                  this$1.reactionPresent
                                )
                              },
                              function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                              },
                            ],
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-fw fa-check fa-sm mr-2 text-success",
                          }),
                          _vm._v("Aanwezig"),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: [
                              function () {
                                return _vm.changePresenceStatus(
                                  this$1.reactionUnknown
                                )
                              },
                              function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                              },
                            ],
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-fw fa-question fa-sm mr-2 text-warning",
                          }),
                          _vm._v("Misschien"),
                        ]
                      ),
                      _c(
                        "a",
                        {
                          staticClass: "dropdown-item",
                          attrs: { href: "#" },
                          on: {
                            click: [
                              function () {
                                return _vm.changePresenceStatus(
                                  this$1.reactionAbsent
                                )
                              },
                              function ($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                              },
                            ],
                          },
                        },
                        [
                          _c("i", {
                            staticClass:
                              "fas fa-fw fa-times fa-sm mr-2 text-danger",
                          }),
                          _vm._v("Afgemeld"),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._m(0),
          ]),
        ]
      ),
      _c(
        "a",
        {
          staticClass:
            "list-group-item list-group-item-action d-flex align-items-center small bg-light font-weight-bold",
        },
        [
          _c("div", { staticClass: "d-flex matchinfo w-100" }, [
            _c("div", { staticClass: "col col-lg align-items-center pr-2" }, [
              _c("span", { staticClass: "club d-flex" }, [
                _c(
                  "svg",
                  {
                    staticClass: "mr-2",
                    staticStyle: {
                      "enable-background": "new 0 0 124 124",
                      width: "15px",
                    },
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      "xmlns:xlink": "http://www.w3.org/1999/xlink",
                      version: "1.1",
                      id: "Laag_1",
                      x: "0px",
                      y: "0px",
                      viewBox: "0 0 124 124",
                      "xml:space": "preserve",
                    },
                  },
                  [
                    _c("path", {
                      staticStyle: { fill: "#343A40" },
                      attrs: {
                        d: "M109.299,0.85H14.701C6.582,0.85,0,7.432,0,15.551v77.871c3.252-0.164,6.211,1.554,7.716,4.266  c1.653-0.801,3.461-1.352,5.393-1.556c8.969-0.95,17.01,5.551,17.96,14.52c0.097,0.912,0.097,1.81,0.045,2.696  c3.777,0.227,7.181,2.769,8.308,6.608c0.313,1.068,0.414,2.145,0.346,3.195h69.531c8.119,0,14.701-6.582,14.701-14.701V15.551  C124,7.432,117.418,0.85,109.299,0.85z M90.907,87.807c0,2.991-2.425,5.416-5.416,5.416h-5.47c-2.991,0-5.416-2.425-5.416-5.416  V69.26h-25.21v18.547c0,2.991-2.425,5.416-5.416,5.416h-5.47c-2.991,0-5.416-2.425-5.416-5.416V36.193  c0-2.991,2.425-5.416,5.416-5.416h5.47c2.991,0,5.416,2.425,5.416,5.416v20.061h25.21V36.193c0-2.991,2.425-5.416,5.416-5.416h5.47  c2.991,0,5.416,2.425,5.416,5.416V87.807z",
                      },
                    }),
                  ]
                ),
                _vm._v("Bekijk training"),
              ]),
            ]),
            _vm._m(1),
          ]),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "bg-white",
          model: {
            value: _vm.showDetails,
            callback: function ($$v) {
              _vm.showDetails = $$v
            },
            expression: "showDetails",
          },
        },
        [
          _vm.showDetails
            ? _c("training-details", {
                attrs: {
                  trainingId: _vm.tile.training.trainingId,
                  eventId: _vm.tile.training.id,
                  teamId: _vm.tile.teamId,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "timescore align-items-center" }, [
      _c("i", { staticClass: "fal fa-chevron-up toggle-icon" }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "timescore align-items-center" }, [
      _c("i", { staticClass: "fal fa-chevron-right toggle-icon" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }