






















import dayjs from 'dayjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tile } from '../../models/tile.model';
import DashboardDutyDetails from './DashboardDutyDetails.vue';

@Component({
  components: {
    DashboardDutyDetails
  }
})
export default class DutyTile extends Vue {
  @Prop()
  tile!: Tile;

  showDetails = false;

  showDutyDetails(): void {
    this.showDetails = !this.showDetails;
  }

  get startTime(): string {
    return dayjs(this.tile.duty.startsAt).format('HH:mm');
  }
  get endTime(): string {
    return dayjs(this.tile.duty.endsAt).format('HH:mm');
  }
}
