























































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Member } from '@/modules/login/models/member.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { Action, Getter } from 'vuex-class';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tile } from '../../models/tile.model';
import DashboardMatchDetails from './DashboardMatchDetails.vue';
import MatchTileDetails from './MatchTileDetails.vue';
import { PresenceStatus } from '../../../../shared/enums/presence-status.enum';
import dayjs from 'dayjs';

@Component({
  components: {
    DashboardMatchDetails,
    MatchTileDetails
  }
})
export default class MatchTile extends Vue {
  showDetails = false;
  showDropdown = false;
  currentPresenceStatus: any = null;
  presentReactions = 0;
  totalReactions = 0;
  fieldName = '';
  teamsNames = '';
  hasResult = false;
  result: any;

  reactionPresent: PresenceStatus = PresenceStatus.Present;
  reactionUnknown: PresenceStatus = PresenceStatus.Unknown;
  reactionAbsent: PresenceStatus = PresenceStatus.Absent;

  @Prop()
  tile!: Tile;

  @Getter
  currentMember!: Member;

  @Action
  changeMatchPresenceStatus!: (payload: { clubId: string, matchId: string, teamId: string, status: PresenceStatus }) => Promise<void>;

  mounted(): void {
    this.currentPresenceStatus = this.tile.match.presence.status;
    const reactions = this.tile.match.presence.reactions;
    this.presentReactions = reactions.present;
    this.totalReactions = reactions.unknown + reactions.present + reactions.absent;
    this.fieldName = `${this.tile.match.pitch?.number} ${this.tile.match.pitch?.name}`;
    this.teamsNames = `${this.tile.match.teamName} - ${this.tile.match.opponentClubName} ${this.tile.match.opponentTeam}`;
    this.hasResult = this.tile.match.result != null && Object.entries(this.tile.match.result).length !== 0 && (this.tile.match.result.homescore !== null || this.tile.match.result.awayscore !== null);
    let res = this.tile.match.result;
    if(res) {
      this.result = `${res.homescore ?? 0}${res.homeextratime ? '/' + res.homeextratime : ''} - ${res.awayscore?? 0}${res.awayextratime ? '/' + res.awayextratime : ''}`;
      if(res.homepenalty)
        this.result += ` (${res.homepenalty} - ${res.awaypenalty})`;
    }
  }

  toggleShowDetails(): void {
    this.showDetails = !this.showDetails;
  }

  toggleShowDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }

  async changePresenceStatus(status: PresenceStatus): Promise<void> {
    if (this.currentPresenceStatus == status) {
      return;
    }

    try {
      const payload = {
        clubId: this.tile.clubId,
        matchId: this.tile.match.id,
        teamId: this.tile.teamId,
        status: status
      }
      await this.changeMatchPresenceStatus(payload);
      if (this.currentPresenceStatus == this.reactionPresent) {
        this.presentReactions--;
      } else if (status == this.reactionPresent) {
        this.presentReactions++;
      }
      this.currentPresenceStatus = status;
      ToastHelper.showSuccess(this.$bvToast, 'Presence status has been changed');
    } catch {
      ToastHelper.showError(this.$bvToast, 'Fout opgetreden. Probeer het nog eens.');
    } finally {
      this.toggleShowDropdown();
    }
  }

  get showTime(): boolean {
    return this.tile.match.isMatchTimeKnown;
  }

  get startTime(): string {
    return dayjs(this.tile.match.startsAt).format('HH:mm');
  }
  get endTime(): string | null {
    return this.tile.match.endsAt ? dayjs(this.tile.match.endsAt).format('HH:mm') : null;
  }
}
