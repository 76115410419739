






















import dayjs from 'dayjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Tile } from '../../models/tile.model';

@Component
export default class RefereeTile extends Vue {
  @Prop()
  tile!: Tile;

  get fieldName(): string {
    return this.tile.referee.pitch?.name ?? '-';
  }
  get teamsNames(): string {
    return this.tile.isHome ?
      (`${this.tile.referee.teamName} - ${this.tile.referee.opponentTeam}`) :
      (`${this.tile.referee.opponentTeam} - ${this.tile.referee.teamName}`)
  }
  get startTime(): string {
    return dayjs(this.tile.referee.startsAt).format('HH:mm');
  }
  get endTime(): string | null {
    return this.tile.referee.endsAt ? dayjs(this.tile.referee.endsAt).format('HH:mm') : null;
  }
}
