


























































































































import { DateHelper } from '@/shared/helpers/date.helper';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { MatchDetails, MatchResults, MatchResultStatus } from '../../models/match-details.model';
import { MatchApiService } from '../../services/impl/match-api.service';
import MatchTileDetilasPerson from './MatchTileDetailsPerson.vue';

@Component({
    components: {
        'match-person': MatchTileDetilasPerson
    }
})
export default class MatchTileDetails extends Vue {

    @Prop()
    matchId!: string;

    @inject()
    matchApiService!: MatchApiService;

    @Getter
    clubId!: string;

    loading = false;
    details: MatchDetails | null = null;

    async created () : Promise<void> {
        await this.fetchDetails();
    }

    async fetchDetails () : Promise<void> {
        try {
            this.loading = true;

            const response = await this.matchApiService.getMatchDetailsForTile(this.clubId, this.matchId);
            this.details = response;
        }
        catch (ex) {
            ToastHelper.showError(this.$bvToast, `Fout opgetreden. Probeer het nog eens.`);
        }
        finally {
            this.loading = false;
        }
    }

    parseTime(time: string) : string {
        const temp = time.split(':');
        return `${temp[0]}:${temp[1]}`;
    }

    matchDate(date: Date) : string {
        return DateHelper.parseDate(date, 'dddd D MMM')
    }

    resultBadgeClass (status: MatchResultStatus | null) : string {
        
        if (status === null) {
            return '';
        }

        if (status === MatchResultStatus.HomeTeamWon) {
            return 'result-badge-won';
        }

        if (status === MatchResultStatus.AwayTeamWon) {
            return 'result-badge-lost';
        }

        return 'result-badge-draw';
    }

    showScore (score: MatchResults | null) : boolean {
        return score?.homeScore !== null && score?.awayScore !== null;
    }

    getScore (score: MatchResults) : string {
        let temp = `${score.homeScore ?? 0} - ${score.awayScore ?? 0}`;

        if (score.homePenalty !== null || score.awayPenalty !== null) {
            temp += `(${score.homePenalty ?? 0} - ${score.awayPenalty ?? 0})`;
        }

        return temp;
    }
}
