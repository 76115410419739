var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass:
            "list-group-item list-group-item-action d-flex align-items-center collapsed",
          on: { click: _vm.showDutyDetails },
        },
        [
          _c("div", { staticClass: "d-flex matchinfo w-100" }, [
            _c("div", { staticClass: "col col-lg align-items-center pr-2" }, [
              _c("span", { staticClass: "club d-block" }, [
                _c("i", { staticClass: "fas fa-coffee mr-2 text-primary" }),
                _c("strong", [
                  _vm._v(
                    _vm._s(_vm.tile.duty.roleName) +
                      ": " +
                      _vm._s(_vm.tile.duty.description)
                  ),
                ]),
              ]),
              _c("span", { staticClass: "club d-block text-muted" }, [
                _vm._v(_vm._s(_vm.tile.duty.displayName)),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "col-4 pl-0 text-right align-items-center" },
              [
                _c("strong", [
                  _vm._v(_vm._s(_vm.startTime) + " - " + _vm._s(_vm.endTime)),
                ]),
              ]
            ),
            _c("div", { staticClass: "timescore align-items-center" }, [
              _c("i", {
                staticClass: "fal toggle-icon",
                class: {
                  "fa-chevron-up": _vm.showDetails,
                  "fa-chevron-left": !_vm.showDetails,
                },
              }),
            ]),
          ]),
        ]
      ),
      _c(
        "b-collapse",
        {
          staticClass: "bg-white",
          model: {
            value: _vm.showDetails,
            callback: function ($$v) {
              _vm.showDetails = $$v
            },
            expression: "showDetails",
          },
        },
        [
          _vm.showDetails
            ? _c("DashboardDutyDetails", {
                attrs: { dutyId: _vm.tile.duty.id },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }