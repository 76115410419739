

















































































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Action, Getter } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import { Team } from '../models/team.model';
import TeamList from './components/TeamList.vue';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { PinnedItem } from '../models/pinned-item.model';
import { Tile } from '../models/tile.model';
import AgendaTile from './components/AgendaTile.vue';
import DutyTile from './components/DutyTile.vue';
import MatchTile from './components/MatchTile.vue';
import RefereeTile from './components/RefereeTile.vue';
import TrainingTile from './components/TrainingTile.vue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

@Component({
  components: {
    TeamList,
    'agenda-tile': AgendaTile,
    'duty-tile': DutyTile,
    'match-tile': MatchTile,
    'referee-tile': RefereeTile,
    'training-tile': TrainingTile
  }
})
export default class Dashboard extends Vue {
  @Action
  private loadDashboard!: () => Promise<void>
  @Action
  private setLoading!: (loading: boolean) => Promise<void>
  @Action
  private setShawAllMenu!: (show: boolean) => Promise<void> 

  @Getter
  checkIfClubHasModule!: (module: string) => boolean;
  @Getter
  currentMemberPersonalTeams!: Team[];
  @Getter
  currentMemberFavouriteTeams!: Team[];
  @Getter
  dashboardPinnedItems!: PinnedItem[];
  @Getter
  dashboardTiles!: Tile[];
  @Getter
  showAllMenu!: boolean;

  loaded = false;

  async created(): Promise<void> {
    try {
      dayjs.extend(utc);
      const token = this.$route.query.showAllMenu;

      if (token && token === 'show') {
        await this.setShawAllMenu(true);
      }
      else {  
        await this.setShawAllMenu(false);
      }

      if (this.showAllMenu) {
        await this.setLoading(true);
        await this.loadDashboard();
      }
      this.loaded = true;
    } catch {
      ToastHelper.showError(this.$bvToast, 'There was an error when loading the dashboard, please try again later.')
    } finally {
      await this.setLoading(false);
    }
  }

  getTileComponent(tile: Tile): string {
    return `${tile.type}-tile`;
  }

  get tilesByDates(): any {
    const tiles = this.dashboardTiles
            
    if (!tiles || tiles.length === 0) {
      return null
    }
    
    const dates: any = {}

    tiles.forEach(tile => {
      const day = dayjs(tile.date).utc().format('dddd DD MMMM YYYY')
      if (!dates[day]) {
        dates[day] = []
      }
      dates[day].push(tile)
    })
    return dates
  }
}
