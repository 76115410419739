



















































import { EventTileDetails } from '../../models/event-details.model';
import { ToastHelper } from '@/shared/helpers/toast.helper';
import { inject } from 'inversify-props';
import { Vue, Component, Prop } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import { IEventApiService } from '@/modules/events/services/event-api.service';
import { Member } from '@/modules/login/models/member.model';
import dayjs from 'dayjs';

@Component
export default class AgendaTileDetails extends Vue {

    loading = false;
    registerLoading = false;
    details: EventTileDetails | null = null;

    @Prop()
    eventId!: string;

    @inject()
    eventApiService!: IEventApiService;

    @Getter
    clubId!: string;    
    @Getter
    currentMember!: Member;

    async created() : Promise<void> {
        await this.fetchDetails();
    }

    async fetchDetails() : Promise<void> {
        try {
            this.loading = true;

            const response = await this.eventApiService.getEventDetailsTile(this.clubId, this.eventId);
            this.details = response;
        }
        catch {
            ToastHelper.showError(this.$bvToast, `${this.$t('dashboard.eventDetails.errorOccured')}`);
        }
        finally {
            this.loading = false;
        }
    }

    async register() : Promise<void> {
        try {
            this.registerLoading = true;

            const response = await this.eventApiService.subscribeEvent(this.clubId, this.eventId, this.currentMember.id, 0);
            
            if (!response) {
                ToastHelper.showError(this.$bvToast, `${this.$t('dashboard.eventDetails.registerError')}`);
                return;
            }

            ToastHelper.showSuccess(this.$bvToast, `${this.$t('dashboard.eventDetails.registerSuccess')}`);
            await this.fetchDetails();
        }
        catch {            
            ToastHelper.showError(this.$bvToast, `${this.$t('dashboard.eventDetails.registerError')}`);
        }
        finally {
            this.registerLoading = false;
        }
    }

    getDate(date: Date) : string {
        return dayjs(date).format('D-M-YYYY HH:mm');
    }

}
